import Switch from '@/components/DSE/Switch';
import MDinput from '@/components/MDinput';
import DateTimePicker from '@/components/DSE/DateTimePicker';

export function getFormDefinition(vue) {
  return {
    accessYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no',
        caption: 'computerAccess.accessYN'
      }
    },
    code: {
      type: MDinput,
      props: {
        caption: 'computerAccess.code',
        readonly: true
      }
    },
    ipAddress: {
      type: MDinput,
      props: {
        caption: 'computerAccess.ip',
        readonly: true
      }
    },
    userAgent: {
      type: MDinput,
      props: {
        caption: 'computerAccess.userAgent',
        readonly: true
      }
    },
    locale: {
      type: MDinput,
      props: {
        caption: 'computerAccess.locale',
        readonly: true
      }
    },
    createdAt: {
      type: DateTimePicker,
      props: {
        caption: 'computerAccess.createdAt',
        disabled: true,
        require: false
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
